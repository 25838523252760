/*
 * @Description: AKJERRT
 * @Date: 2022-07-04 11:18:53
 * @LastEditTime: 2022-08-05 10:42:23
 * @FilePath: \linkgap-front\src\views\dealer_balance\thead\columns.js
 */
export const columns =
  [
    {
      title: '经销商名称',
      dataIndex: 'dealerName',
      key: 'dealerName',
      align: 'center',
    },
    {
      title: '大区',
      dataIndex: 'mhiacAreaName',
      key: 'mhiacAreaName',
      align: 'center',
    },
    {
      title: '事务所',
      dataIndex: 'firmName',
      key: 'firmName',
      align: 'center',
    },
    {
      title: "欠款余额",
      align: 'center',
      className: 'headerYellow',
      children: [
        {
          title: '欠款',
          dataIndex: 'arrears',
          key: 'arrears',
          align: 'center',
          className: 'headerTextRed',
          scopedSlots: { customRender: 'arrears' },
        },
      ]
    },
    {
      title: "可使用余额",
      align: 'center',
      className: 'centerGreen',
      children: [
        {
          title: '可使用余额',
          dataIndex: 'canUseAmount',
          key: 'canUseAmount',
          align: 'center',
          scopedSlots: { customRender: 'canUseAmount' },
          className: 'headerTextDarkGreen',
        },
        {
          title: '资金余额',
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          scopedSlots: { customRender: 'amount' },
          className: 'headerTextShallowGreen',
        },
        {
          title: '信用额度',
          dataIndex: 'limitAmount',
          key: 'limitAmount',
          align: 'center',
          scopedSlots: { customRender: 'limitAmount' },
          className: 'headerTextShallowGreen',
        },
      ],
    },
    {
      title: "折扣额",
      align: 'center',
      className: 'centerBlue',
      children: [
        {
          title: '折扣余额',
          dataIndex: 'rebateAmount',
          key: 'rebateAmount',
          align: 'center',
          className: 'headerTextBlue',
          scopedSlots: { customRender: 'rebateAmount' },
        },
      ]
    },
    // {
    //   title: '查看对账单',
    //   dataIndex: 'regDate',
    //   key: 'regDate',
    //   align: 'center',
    //   scopedSlots: { customRender: 'operation' }
    // },
  ]
